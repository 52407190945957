<template>
  <div class="collection-pen-test">
    <!--笔试题-->
    <div class="exercises">
      <h3>
        <span>
          <router-link to="/usr/qa">面试问答</router-link>
        </span>
        <span>笔试题</span>
      </h3>
      <ul>
        <li v-for="i in 8" :key="i">
          <Qa WithCancelFavorite/>
        </li>
      </ul>
      <!--分页 -->
      <pagination/>
    </div>
  </div>
</template>

<script>
import Qa from '@/components/question/qa'
export default {
  components: { Qa }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/variables";
  .collection-pen-test{
    .exercises{
      background-color: $background-color;
      padding: 0 20px;
      box-sizing: border-box;
      box-shadow: $background-shadow;
      border-radius: 20px;
      h3{
        border-bottom: 1px solid #d8d8d8;
        margin: 0 0 25px 0;
        padding: 15px 10px 5px 10px;
        font-weight: normal;
        font-size: 16px;
        color: $font-color-third;
        span{
          margin-right: 25px;
          &:nth-of-type(2){
            color: $primary-color;
          }
        }
      }
      ul{
        margin: 0;
        padding: 0;
      }
      //分页
      .el-pagination{
        padding-bottom: 30px;
      }
    }
  }
</style>
