<template>
  <div class="collection-interview">
    <div class="picture">
      <img src="../../../assets/images/avatar.png">
    </div>
    <!-- 收藏的问答题 -->
    <div class="main">
      <p>
        <span>Vue的生命周期钩子有哪些？调用接口通常是哪个钩子函数？</span>
        <!-- 点击数 -->
        <span v-if="withReadCount">
          <Icon type="icon-chakan2"/>
          <em>2400</em>
        </span>
        <!-- 收藏数 -->
        <span v-if="withFavoriteCount">
          <Icon type="icon-shoucang"/>
          <em>2400</em>
        </span>
        <!-- 投票数 -->
        <span v-if="withVoteCount">
          <Icon type="icon-zu"/>
          <em>2400</em>
        </span>
        <!-- 错误数 -->
        <span v-if="withFaultCount" class="error">
         <i class="el-icon-close"></i>1
        </span>
        <!-- 取消收藏 -->
        <span v-if="WithCancelFavorite" class="cancel">
          取消收藏
        </span>
      </p>
      <p>
        <Icon type="icon-shipin" />
        <span>视频讲解</span>
        <span>初级</span>
        <span>Vue</span>
      </p>
      <p>
        <img src="../../../assets/images/avatar.png">
        <span>我觉得你说的对</span>
        <Icon type="icon-dianzan"/>
        <span>120</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QA',
  props: {
    // 是否展示查看数
    withReadCount: {
      type: Boolean,
      default: false
    },
    // 是否展示收藏数
    withFavoriteCount: {
      type: Boolean,
      default: false
    },
    // 是否展示投票数
    withVoteCount: {
      type: Boolean,
      default: false
    },
    // 是否展示错误数
    withFaultCount: {
      type: Boolean,
      default: false
    },
    // 是否展示取消收藏按钮
    WithCancelFavorite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../../assets/style/variables";
  .collection-interview{
    display: flex;
    margin-bottom: 25px;
    .picture{
      flex-shrink: 0;
      margin: 0 20px;
      img{
        width: 50px;
        border-radius: 50%;
      }
    }
    //收藏的问答题
    .main{
      flex-grow: 1;
      p{
        width: 100%;
        margin: 0;
        //大概介绍收藏的问答题的意思
        &:nth-of-type(1){
          display: flex;
          flex-shrink: 0;
          line-height: 25px;
          span{
            line-height: 25px;
            flex-shrink: 0;
            color: $font-color-third;
            //观看，收藏，投票数
            em{
              font-style: normal;
              font-size: 12px;
              margin-left: 5px;
            }
            i{
              position: relative;
              top: 2px;
              margin-left: 10px;
            }
            &:first-of-type{
              font-size: 16px;
              flex-grow: 1;
              color: $font-color;
            }
          }
          //错误
          .error{
            font-size: 15px;
            i{
              height: 13px;
              background-color: red;
              border-radius: 50%;
              color: #eeeeee;
              font-size: 13px;
              margin-right: 5px;
              position: relative;
              top: -0.3px;
            }
          }
          //取消收藏
          .cancel{
            flex-shrink: 0;
            text-align: center;
            width: 100px;
            height: 25px;
            background-color: $background-color-second;
            color: $font-color-third;
            border-radius: 30px;
            font-size: 12px;
          }
        }
        //收藏的问答题类型
        &:nth-of-type(2) {
          margin: 8px 0;
          span{
            font-size: 13px;
            margin-right: 15px;
          }
          i{
            margin-right: 5px;
            position: relative;
            top: 1px;
            color: $primary-color
          }
        }
        //评论点赞最多的话
        &:nth-of-type(3){
          width: 188px;
          height: 25px;
          background-color: $background-color-second;
          text-align: center;
          border-radius: 30px;
          line-height: 25px;
          span{
            margin: 0 5px;
            font-size: 12px;
          }
          i{
            font-size: 13px;
          }
          img{
            width: 15px;
            border-radius: 50%;
            position: relative;
            top: 2px;
          }
        }
      }
    }
  }
</style>
